var thBlocs = {

    toInit: [],

    init:function(){
        thPWA.callbacks.thblock = thBlocs.initAll;
    },

    initAll: function(){
        $.each(thBlocs.toInit,function(k,blocMethodName){
           thBlocs[blocMethodName]();
        });
    },

    add: function(name,myFunction,doNotInitNow){
        thBlocs[name] = myFunction;
        thBlocs.toInit.push(name);
        if(doNotInitNow != true){
            thBlocs[name]();
        }
    }

};

thBlocs.init();

var thuriaSearch = {

    scriptLoaded: false,

    client: null,
    index: null,

    input: null,
    searchContainer: null,
    searchScrollContainer: null,

    lastSearch: 0,

    lastText: null,
    lastResultNbPage: 0,
    lastHitsCountPerIndex: {},
    lastPage: 0,
    lastPostHits: null,
    lastPostHitGenerated: 0,
    intervalGenerateHit: null,
    intervalGeneratePostHit: null,

    lockNextPage: false,
    noresultPhrase: null,

    init: function(id,key,index,input,searchContainer,searchScrollContainer){
        if(typeof algoliasearch == 'undefined'){
            setTimeout(function(){
                thuriaSearch.init(id,key,index,input,searchContainer);
            },500);
            return false;
        }

        thuriaSearch.client = algoliasearch(id, key);
        thuriaSearch.index = index;
        thuriaSearch.input = input;
        thuriaSearch.searchContainer = searchContainer;
        thuriaSearch.searchScrollContainer = searchScrollContainer;
        thuriaSearch.noresultPhrase = thuriaSearch.searchContainer.getAttribute('data-noresult');
        thuriaSearch.initInputEvents();
        thuriaSearch.initScrollEvents();

        return true;
    },

    initInputEvents: function(){
        thuriaSearch.input.addEventListener('keydown',function(){
            setTimeout(function(){
                thuriaSearch.search(thuriaSearch.input.value);
            },25);
        });
    },

    initScrollEvents: function(){
      thuriaSearch.searchScrollContainer.addEventListener('touchstart',function() {
        if(thuriaSearch.input.id === document.activeElement.id){
          document.getElementById('search-fake-btn').focus();
        }
      });
      thuriaSearch.searchScrollContainer.addEventListener('scroll',function(){

            if(thuriaSearch.searchScrollContainer.scrollTop > 0){
                if((thuriaSearch.searchScrollContainer.scrollHeight-thuriaSearch.searchScrollContainer.scrollTop-thuriaSearch.searchScrollContainer.getBoundingClientRect().height) < 1024){
                    thuriaSearch.nextPage();
                }
            }
        });

      thuriaSearch.searchContainer.addEventListener('scroll',function(){
        if(thuriaSearch.searchContainer.scrollTop > 0){
          if((thuriaSearch.searchContainer.scrollHeight-thuriaSearch.searchContainer.scrollTop-thuriaSearch.searchContainer.getBoundingClientRect().height) < 1024){
            thuriaSearch.nextPage();
          }
        }
      });
    },

    generateQueries: function(query,checkLastResult){
        var finalQueries = [];
        $.each(thuriaSearch.index,function(k,indexName){
            var isOk = false;
            if(checkLastResult){
                if(thuriaSearch.lastHitsCountPerIndex[indexName] > 0){
                    isOk = true;
                }
            }

          if(!checkLastResult || isOk){
                var q;
                if(indexName.match(/^lei\_/)){
                  q = $.extend(false,$.parseJSON(JSON.stringify(query)),{indexName:indexName});
                }else{
                  q = $.extend(false,$.parseJSON(JSON.stringify(query)),{indexName:indexName});

                  if(!q.params){
                      q.params = {};
                  }
                  q.params.filters = "taxonomies.language:"+algoliaCurrentLanguage;
                }
                finalQueries.push(q);
            }
        });
        return finalQueries;
    },

    search: function(text){
        thuriaSearch.lastText = text;
        thuriaSearch.lastSearch++;
        var queryId = thuriaSearch.lastSearch;

        var q = thuriaSearch.generateQueries({query:thuriaSearch.lastText,params:{page:0,hitsPerPage:24}});

        thuriaSearch.client.search(q,function(err,result){
            if(!err){
                var results = result.results;
                thuriaSearch.handleSearchResult(results,queryId);
            }
        });

    },

    nextPage: function(){
        if(thuriaSearch.lockNextPage == false && thuriaSearch.lastResultNbPage > 1 && thuriaSearch.lastResultNbPage > thuriaSearch.lastPage){
            thuriaSearch.lockNextPage = true;

            var q = thuriaSearch.generateQueries({query:thuriaSearch.lastText,params:{page:thuriaSearch.lastPage+1,hitsPerPage:24}},true);


            thuriaSearch.client.search(q,function(err,result){
                if(!err){
                    var results = result.results;
                    var lastPage = null;
                    var lastNbPage = 0;
                    $.each(results,function(k,r){
                        if(r.nbPages > lastNbPage){
                            lastNbPage = r.nbPages;
                            lastPage = r.page;
                            thuriaSearch.lastHitsCountPerIndex[r.index] = r.hits.length;
                        }
                    });
                    thuriaSearch.lastPage = lastPage;
                    thuriaSearch.lastResultNbPage = lastNbPage;
                    thuriaSearch.handleResponse(results);
                }
            });
        }
    },

    handleSearchResult: function(results,queryId){
        if(thuriaSearch.lastSearch == queryId){
            thuriaSearch.searchContainer.innerHTML = '';
            thuriaSearch.searchContainer.scrollTop = 0;
            thuriaSearch.lockNextPage = false;
            var lastPage = null;
            var lastNbPage = 0;

            $.each(results,function(k,r){
                if(r.nbPages > lastNbPage){
                    lastNbPage = r.nbPages;
                    lastPage = r.page;
                }
                thuriaSearch.lastHitsCountPerIndex[r.index] = r.hits.length;
            });

            thuriaSearch.lastPage = lastPage;
            thuriaSearch.lastResultNbPage = lastNbPage;

            if(lastNbPage > 0){
                thuriaSearch.handleResponse(results);
            }else{
                thuriaSearch.searchContainer.innerHTML = '<p>'+thuriaSearch.noresultPhrase+'</p>';
            }
        }
    },

    handleResponse: function(results){

        var html = '';

        $.each(results,function(k,result){
            $.each(result.hits,function(k2,hit){
                html += cardGenerator.generate(hit);
            });
        });

        thuriaSearch.searchContainer.innerHTML += html;

        setTimeout(function(){
            thuriaSearch.lockNextPage = false;
            if (typeof updateOuvertureLEIID != 'undefined') {
                updateOuvertureLEIID();
            }
        },100);
    },

};
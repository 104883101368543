var listingMapManager = {
    mode: 'desktop',

    isInit: false,
    isInitMapDesktop: false,

    map: null,
    mapDesktop: null,
    markers: {},
    visibleMarkers: [],
    markerPlace: null,
    markersCluster: null,
    loadedKmls: [],
    isHoveringCard: false,

    init: function (initMobile) {

        if ($('.page-listing-lei').length > 0 && listingMapManager.isInit === false) {
            $('a[href="#show-overlay-map"]').on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                listingMapManager.handleMobileMapOverlayOpen();
            });

            if (isTabletPortraitOrSmalller()) {
                listingMapManager.setMode('mobile');
            }

            if (isTabletPortraitOrSmalller() && !initMobile) {
                return false;
            }

            listingMapManager.initMapsDesktop();

            listingMapManager.updateFromListing();
            listingMapManager.isInit = true;
        }
    },

    initMapsDesktop: function () {
        if (listingMapManager.isInitMapDesktop) {
            return false;
        }
        if (listingMapManager.mode == 'desktop') {
            listingMapManager.isInitMapDesktop = true;
            listingMapManager.mapDesktop = th_maps.initSingleMap($('.page-listing-lei .maps')[0]);
            listingMapManager.map = listingMapManager.mapDesktop;
        }
    },

    setMode: function (mode) {
        listingMapManager.mode = mode;
    },

    handleMobileMapOverlayOpen: function () {
        mapOverlayManager.open();
        //mapOverlayManager.showTagsMobile($('#tags').html());
        mapOverlayManager.showPaginationMobile($('.bloc-pagination .pagination').html());
        listingMapManager.map = mapOverlayManager.map;
        listingMapManager.updateFromListing();

        if (algoliaFacette.geoQuery) {
            mapOverlayManager.showMarkerCenter();
            var splited = algoliaFacette.geoQuery.aroundLatLng.split(', ');
            mapOverlayManager.markerCenter.setLatLng({lat: (splited[0] * 1), lng: (splited[1] * 1)});
        }
        return true;
    },

    move: function (lat, lng) {
        if (!listingMapManager.map) {
            return null;
        }

        listingMapManager.map.panTo({lat: lat, lng: lng});
    },

    getMarker: function (lat, lng, lei_id, kml) {
        if (!listingMapManager.markers[lei_id]) {
            var marker = th_maps.createMarker(null, {lat: lat, lng: lng}, 'default');
            var infoWindow = L.popup({
                className: 'custom-popup-thuria',
                maxWidth: 250,
                minWidth: 250
            }).setContent($('[data-vignettelei="' + lei_id + '"]').html());
            th_maps.infoWindows.push(infoWindow);
            if (document.body.clientWidth > 922) {
                marker.bindPopup(infoWindow);
            }
            marker.addEventListener('click', function () {
                if (document.body.clientWidth > 922) {
                    infoWindow.openPopup();
                } else {
                    var ol = $('#listing-mobile-lei [data-vignettelei="' + lei_id + '"]').offsetLeft();
                    var olWrapper = 27;
                    var sl = $('.listing-map-mobile').scrollLeft();
                    $('.listing-map-mobile').animate({scrollLeft: (sl + ol - olWrapper)}, 300);
                }
            });

            listingMapManager.markers[lei_id] = marker;
        }

        return listingMapManager.markers[lei_id];
    },

    hideAllMarker: function () {
        if (!listingMapManager.map) {
            return null;
        }

        if (listingMapManager.markersCluster) {
            listingMapManager.map.removeLayer(listingMapManager.markersCluster);
        }

    },

    updateFromListing: function () {
        if (!listingMapManager.map) {
            return null;
        }

        if (listingMapManager.mode === 'mobile') {
            setTimeout(function () {
                mapOverlayManager.updateContentMiniCards($('.wrapper-content .listing-cards').html());
                mapOverlayManager.showPaginationMobile($('.bloc-pagination .pagination').html());
                $('.listing-map-mobile').scrollLeft(0);

                return true;
            }, 200);
        }

        listingMapManager.hideAllMarker();

        var markers = L.markerClusterGroup({showCoverageOnHover: false});

        var markerlist = [];
        $('.wrapper-content .listing-cards > a[data-vignettelei]').each(function () {
            var lei_id = $(this).attr('data-vignettelei');
            var lat = $(this).attr('data-lat');
            var lng = $(this).attr('data-lng');

            var marker = mapOverlayManager.getMarker(lat, lng, lei_id, kml);
            markerlist.push(marker);
            markers.addLayer(marker);

            listingMapManager.visibleMarkers.push(lei_id);

            var kml = $(this).attr('data-kml');

            $(this).on('mouseenter', function () {
                marker.setIcon(th_maps.markersIcons['default-hover']);

                if (typeof kml !== "undefined") {
                    listingMapManager.isHoveringCard = true;

                    if (listingMapManager.loadedKmls[lei_id] === undefined) {
                        listingMapManager.loadedKmls[lei_id] = omnivore.kml(kml);

                        listingMapManager.loadedKmls[lei_id]
                            .on('ready', function (e) {
                                var highlightStyle = {
                                    fillColor: "00FFFFFF",
                                    weight: 3,
                                    opacity: 1,
                                    color: "#D74537",
                                    fillOpacity: 0.0
                                };

                                this.setStyle(highlightStyle);
                            })
                            .on('error', function () {
                                listingMapManager.loadedKmls[lei_id] = undefined
                            })
                            .addTo(listingMapManager.map)

                    } else {
                        listingMapManager.loadedKmls[lei_id].addTo(listingMapManager.map)
                    }

                }


            }).on('mouseleave', function () {
                listingMapManager.isHoveringCard = false;
                marker.setIcon(th_maps.markersIcons['default']);
                if (typeof kml !== "undefined") {
                    listingMapManager.map.removeLayer(listingMapManager.loadedKmls[lei_id]);
                }
            });
        });

        /*
         if(listingMapManager.markersCluster){
         map.removeLayer(listingMapManager.markersCluster);
         }
         */
        listingMapManager.markersCluster = markers;
        listingMapManager.map.addLayer(listingMapManager.markersCluster);
        listingMapManager.map.fitBounds(listingMapManager.markersCluster.getBounds(), {padding: [40, 40]});
    },

    setMarkerPlace: function (lat, lng) {
        if (!listingMapManager.map) {
            return null;
        }

        if (lat == 0 && lng == 0) {
            listingMapManager.map.removeLayer(listingMapManager.markerPlace);
            return false;
        }

        if (listingMapManager.mode == 'mobile') {
            return false;
        }
        if (!listingMapManager.markerPlace) {
            listingMapManager.markerPlace = th_maps.createMarkerTarget('markerplace');
        }
        listingMapManager.markerPlace.setLatLng({lat: lat, lng: lng});
        listingMapManager.map.addLayer(listingMapManager.markerPlace);

    },
};
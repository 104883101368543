thBlocs.add('noterequest',function(){

    if($('[data-ajax="note-request"]').length > 0){
        $('[data-ajax="note-request"] label').on('click',function(){

            var value = $('#'+$(this).attr('for')).val();
            if(value > 0){
                $('.page-rate').addClass('voted');
                setTimeout(function(){
                    $('.page-rate').addClass('form-removed');
                    $('[data-ajax="note-request"]').remove();
                },300);

                $.post($('[data-ajax="note-request"]').attr('action'),{id:$('[data-ajax="note-request"]').attr('data-id'),note:value,action:'noterequest'},function(response){
                    var results = $.parseJSON(response);
                    var note = Math.round(results.note);
                    if($('.page-rate .note').length > 0 ){
                        $('.page-rate .note .icon-ico-heart').each(function (index) {
                            if(index < note ){
                                if($(this).hasClass('disabled')) {
                                    $(this).removeClass('disabled');
                                }
                            } else {
                                if(!$(this).hasClass('disabled')) {
                                    $(this).addClass('disabled');
                                }
                            }
                        })
                    } else {
                        var html = '<div class="global-rate  note">';
                        for (var i = 1; i <= 5 ; i++) {
                            if(note >= i) {
                                html += '<span class="icon-ico-heart"></span>';
                            } else {
                                html += '<span class="icon-ico-heart disabled"></span>';
                            }
                        }
                        html += '<span class="txt">(1)</span>';
                        html += '</div>';
                        $('.page-rate .rate').append(html);
                    }
                });
            }
        });
    }

});
var mapOverlayManager = {

    id: null,
    el: null,
    $el: null,

    mapIsInit: false,
    map: null,

    myPosition: null,
    myPositionMarker: null,

    markers: [],
    markersDisplayed: [],
    markersCluster: null,
    markerCenter: null,

    timeoutMobileScrollCards: null,
    mobileScrollLastMarkerId: null,

    init: function (id) {
        mapOverlayManager.id = id;
        mapOverlayManager.el = document.getElementById(id);
        mapOverlayManager.$el = $(mapOverlayManager.el);


        $('[data-overlay-map-close]').on('click', function () {
            mapOverlayManager.close();
        });

        mapOverlayManager.parseOpenBtn(document);


        thPWA.callbacks.mapOverlay = function () {
            mapOverlayManager.parseOpenBtn($('#pwa-content').get(0));
        };

    },

    parseOpenBtn: function (wrapper) {
        $('a[href="#overlay-map"],a[href="#show-overlay-map"],[data-overlay-map-open]', wrapper).on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            mapOverlayManager.handleClickOpenBtn(this);
        });
    },

    handleClickOpenBtn: function (el) {
        mapOverlayManager.hideTagsMobile();
        mapOverlayManager.setContentMiniCards('');
        mapOverlayManager.clear();
        mapOverlayManager.open();

        var datas = $(el).data();

        if (datas.marker && datas.lat && datas.lng) {
            var marker = th_maps.createMarker(null, {
                lat: datas.lat,
                lng: datas.lng
            }, th_maps.markersIcons[datas.marker]);
            mapOverlayManager.addMarker(marker);
            mapOverlayManager.map.panTo({lat: datas.lat, lng: datas.lng});
        }

        if (datas.itineraire == true && datas.lat && datas.lng) {
            mapOverlayManager.showItineraire({lat: datas.lat, lng: datas.lng});
        }


        if (datas.kml) {
            var kmlLayer = omnivore.kml(datas.kml).on("ready", function (e) {

                var highlightStyle = {
                    fillColor: "00FFFFFF",
                    weight: 1,
                    opacity: 1,
                    color: "#000000",
                    fillOpacity: 0.0
                };
                if (datas.kmlBgcolor) {
                    highlightStyle.fillColor = datas.kmlBgcolor;
                }
                if (datas.kmlWeight) {
                    highlightStyle.weight = datas.kmlWeight;
                }
                if (datas.kmlOpacity) {
                    highlightStyle.opacity = datas.kmlOpacity;
                }
                if (datas.kmlColor) {
                    highlightStyle.color = datas.kmlColor;
                }
                if (datas.kmlBgopacity) {
                    highlightStyle.fillOpacity = datas.kmlBgopacity;
                }

                this.setStyle(highlightStyle);
                mapOverlayManager.map.fitBounds(e.target.getBounds());
                setTimeout(function () {
                    mapOverlayManager.map.fitBounds(e.target.getBounds());
                }, 500);
            });

            mapOverlayManager.addMarker(kmlLayer);
        }
    },

    clear: function () {

        $('> .btn', mapOverlayManager.el).addClass('hide');

        for (var i = 0; i < mapOverlayManager.markersDisplayed.length; i++) {
            mapOverlayManager.removeMarker(i);
        }

        mapOverlayManager.hideMarkerCenter();
    },

    open: function () {
        mapOverlayManager.hideTagsMobile();
        mapOverlayManager.clear();
        mapOverlayManager.clear();

        if (mapOverlayManager.mapIsInit === false) {
            mapOverlayManager.mapIsInit = true;
            mapOverlayManager.initMap();

            $('#overlay-map .cards-mini').on('scroll', function () {
                mapOverlayManager.handleScrollMobileCards(this, function (lei_id) {
                    $('[data-vignettelei].active', '#overlay-map').removeClass('active');
                    $('[data-vignettelei="' + lei_id + '"]', '#overlay-map').addClass('active');
                });
            });
        }

        th_overlayModified.open(mapOverlayManager.id, false, false);

        setTimeout(function () {
            if (mapOverlayManager.map) {
                mapOverlayManager.map.invalidateSize();
            }
        });

    },

    close: function () {
        th_overlayModified.close(mapOverlayManager.id, true, false);
        $('body').removeClass('no-scroll');
    },

    showItineraire: function (latlng) {
        var latlngstring = latlng.lat + ',' + latlng.lng;
        $('> .btn', mapOverlayManager.el).attr('href', 'https://maps.google.com/?saddr=Ma position&daddr=' + latlngstring).removeClass('hide');
    },

    showMarkerCenter: function (draggable, dragCallback) {
        if (!mapOverlayManager.markerCenter) {
            mapOverlayManager.markerCenter = th_maps.createMarkerTarget();
        }
        mapOverlayManager.addMarker(mapOverlayManager.markerCenter);

        if (draggable) {
            mapOverlayManager.markerCenter.dragging.enable();
            mapOverlayManager.markerCenter.off('dragend');
            mapOverlayManager.markerCenter.on('dragend', dragCallback);
        } else {
            mapOverlayManager.markerCenter.dragging.disable();
        }
    },

    hideMarkerCenter: function () {
        if (mapOverlayManager.markerCenter) {
            mapOverlayManager.map.removeLayer(mapOverlayManager.markerCenter);
        }
    },

    initMap: function () {

        mapOverlayManager.map = th_maps.initSingleMap($('.maps', mapOverlayManager.el).get(0));

    },

    addMarker: function (marker) {
        if (mapOverlayManager.map) {
            mapOverlayManager.map.addLayer(marker);
            mapOverlayManager.markersDisplayed.push(marker);
        }
    },

    removeMarker: function (i) {
        if (mapOverlayManager.markersDisplayed[i]) {
            mapOverlayManager.map.removeLayer(mapOverlayManager.markersDisplayed[i]);

            mapOverlayManager.markersDisplayed = mapOverlayManager.markersDisplayed.filter(function (ele) {
                return ele != mapOverlayManager.markersDisplayed[i];
            });
        }
    },

    getMarker: function (lat, lng, lei_id) {
        if (!mapOverlayManager.markers[lei_id]) {

            const marker = th_maps.createMarker(null, {lat: lat, lng: lng}, 'default');
            const card = $('[data-vignettelei="' + lei_id + '"]')[0];

            let width = 250;
            if($('body').hasClass('site_velo') && $(card).hasClass('bloc-card-lei-itineraire') ) {
                width = 460;
            }
            var infoWindow = L.popup({
                className: 'card bloc-card-sit custom-popup-thuria',
                maxWidth: width,
                minWidth: width
            }).setContent(card.outerHTML);

            th_maps.infoWindows.push(infoWindow);
            if (document.body.clientWidth > 922) {
                marker.bindPopup(infoWindow);
            }

            marker.addEventListener('click', function () {
                if (document.body.clientWidth > 922) {
                    infoWindow.openPopup();
                } else {
                    var ol = $('.listing-map-mobile [data-vignettelei="' + lei_id + '"]').offsetLeft();
                    var olWrapper = 27;
                    var sl = $('.cards-mini', '#overlay-map').scrollLeft();
                    $('.cards-mini', '#overlay-map').animate({scrollLeft: (sl + ol - olWrapper)}, 300);
                }
            });


            mapOverlayManager.markers[lei_id] = marker;
        }

        return mapOverlayManager.markers[lei_id];
    },


    showTagsMobile: function (htmlContent) {
        if (htmlContent == '') {
            mapOverlayManager.hideTagsMobile();
        }
        if ($('#tags-mobile').length == 0) {
            $('#overlay-map .data-bottom').append('<ul class="wrapper-critere" id="tags-mobile">' + htmlContent + '</ul>');
            $('#tags-mobile > *').each(function () {
                $(this).on('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    var dataSlug = $(this).data('slug');
                    $('#tags [data-slug="' + dataSlug + '"]').click();
                });
            });
        }
    },

    hideTagsMobile: function () {
        $('#tags-mobile').remove();
    },

    showPaginationMobile: function (htmlContent) {
        if (htmlContent == '' || !htmlContent) {
            mapOverlayManager.hideTagsMobile();
        }

        $('#overlay-map .data-bottom .paginate-mobile').remove();

        if (htmlContent) {
            $('#overlay-map .data-bottom').append('<div class="paginate-mobile">' + htmlContent + '</div>');

            $('.paginate-mobile .btn-next', '#overlay-map .data-bottom').on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                algoliaFacette.setPage(algoliaFacette.pageQuery + 2);
            });
            $('.paginate-mobile .prev', '#overlay-map .data-bottom').on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                algoliaFacette.setPage(algoliaFacette.pageQuery);
            });
        }
    },


    updateContentMiniCards: function (htmlContent) {
        mapOverlayManager.setContentMiniCards(htmlContent);

        if (mapOverlayManager.markersCluster && mapOverlayManager.map) {
            mapOverlayManager.map.removeLayer(mapOverlayManager.markersCluster);
        }

        mapOverlayManager.markersCluster = L.markerClusterGroup({showCoverageOnHover: false});

        $('#overlay-map-result-cards > a.card').each(function () {
            var lei_id = $(this).attr('data-vignettelei');
            var lat = $(this).attr('data-lat');
            var lng = $(this).attr('data-lng');

            var marker = mapOverlayManager.getMarker(lat, lng, lei_id);
            mapOverlayManager.markersCluster.addLayer(marker);
        });

        mapOverlayManager.addMarker(mapOverlayManager.markersCluster);
        mapOverlayManager.map.fitBounds(mapOverlayManager.markersCluster.getBounds(), {padding: [100, 50]});

    },

    setContentMiniCards: function (html, append) {
        if (!append) {
            if (!$('.paginate-mobile .btn-next', '#overlay-map .data-bottom').hasClass("disabled")) {
                $('#overlay-map-result-cards').html(html + '<a class="btn-next" href="#next">Résultats Suivants</a>');
                $('#overlay-map-result-cards .btn-next').on('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    $('.bloc-pagination .btn-next').click();
                });
            } else {
                $('#overlay-map-result-cards').html(html);
            }
        } else {
            $('#overlay-map-result-cards').append(html);
        }
    },


    handleScrollMobileCards: function (el, callback) {
        clearTimeout(mapOverlayManager.timeoutMobileScrollCards);
        mapOverlayManager.timeoutMobileScrollCards = setTimeout(function () {
            var current = mapOverlayManager.mobileCheckCurrentCards(el);
            mapOverlayManager.mobileChangeCurrent(current);
            if (callback) {
                callback(current);
            }
        }, 50);
    },

    mobileCheckCurrentCards: function (el) {
        var curr = null;
        $('> div > div', el).each(function () {
            var o = $(this).offset();

            if (o.left > -100 && o.left < 160) {
                curr = this;
            }
        });

        if (curr) {
            return $(curr).data('vignettelei');
        }

        return false;
    },

    mobileChangeCurrent: function (markerId) {
        if (markerId != mapOverlayManager.mobileScrollLastMarkerId) {
            if (mapOverlayManager.mobileScrollLastMarkerId) {
                mapOverlayManager.markers[mapOverlayManager.mobileScrollLastMarkerId].setIcon(th_maps.markersIcons['default']);
            }

            if (mapOverlayManager.markers[markerId]) {
                mapOverlayManager.mobileScrollLastMarkerId = markerId;

                var zoom = mapOverlayManager.map.getZoom();
                if (mapOverlayManager.markers[markerId].__parent) {
                    if (mapOverlayManager.markers[markerId].__parent._zoom) {
                        zoom = mapOverlayManager.markers[markerId].__parent._zoom + 1
                    }
                }
                mapOverlayManager.markers[markerId].setIcon(th_maps.markersIcons['default-hover']);
                if (zoom > 16) {
                    zoom = 16;
                }
                mapOverlayManager.map.flyTo(mapOverlayManager.markers[markerId].getLatLng(), zoom);

            }
        }
    }
};


mapOverlayManager.init('overlay-map');






thBlocs.add('fiche_lei', function () {

    if ($('.page-lei').length > 0) {
        $('a[href^="tel:"][data-tel]').on('click', function (e) {
            if (document.body.clientWidth > 420) {
                e.preventDefault();
                $(this).html($(this).data('tel')).off('click');
            }
        });

        $('a[href="#galerie"]').on('click', function (e) {

            e.preventDefault();
            e.stopPropagation();

            $('a.zoombox.isfirst').click();

        });

        if ($('.steps-wrapper').length > 0) {

            $('.steps-wrapper .nav-prev').on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                $parent = $(this).closest('.steps-wrapper');
                $parent.attr('data-step', ($parent.attr('data-step') * 1) - 1);
            });

            $('.steps-wrapper .nav-next').on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                var errors = formPageLeiValidateCurrentStep();
                formPageLeiDisplayErrors(errors);

                if (errors.length == 0) {
                    $parent = $(this).closest('.steps-wrapper');
                    $parent.attr('data-step', ($parent.attr('data-step') * 1) + 1);
                }

            });

            $('.overlay-filtre form').on('submit', function (e) {
                e.preventDefault();
                e.stopPropagation();
                var recaptchaSitekey = this.getAttribute("data-sitekey");
                var errors = formPageLeiValidateCurrentStep();
                formPageLeiDisplayErrors(errors);

                if (!errors.length) {
                    var _self = this;
                    if ($('.steps-wrapper', this).attr('data-step') * 1 == ($('.steps-wrapper', this).attr('data-maxstep') * 1) - 1) {
                        if (lockSendEmailAAA === 0) {
                            lockSendEmailAAA = 1;
                            grecaptcha.ready(function () {
                                grecaptcha.execute(recaptchaSitekey, {action: 'submit'}).then(function (token) {
                                    // Add your logic to submit to your backend server here.
                                    var recaptchaContainer = _self.querySelector("input[name='g-recaptcha-response']");

                                    if (recaptchaContainer) {
                                        recaptchaContainer.value = token;

                                        $.post(document.location.href, $(_self).serialize(), function (result) {
                                            if (result.success) {
                                                $('.steps-wrapper', _self).attr('data-step', $('.steps-wrapper', _self).attr('data-maxstep'));
                                            } else if (result.errors && result.errors.required) {
                                                $.each(result.errors.required, function (v, k) {
                                                    if (k == 'recaptcha') {
                                                        formPageLeiDisplayErrors([{
                                                            field: $('#form-info-lei .g-recaptcha').parent(),
                                                            message: 'Ce champ est obligatoire.',
                                                        }]);
                                                    }
                                                });
                                            }
                                            lockSendEmailAAA = 0;
                                        }, 'json');
                                    }
                                });
                            });
                        }

                    } else {
                        $('.steps-wrapper', this).attr('data-step', ($('.steps-wrapper', this).attr('data-step') * 1) + 1);
                    }
                }
            });
        }
    }
});

var lockSendEmailAAA = 0;

function formPageLeiDisplayErrors(errors) {
    $('p.frm_error', '.steps-wrapper').remove();

    $.each(errors, function (k, error) {
        $(error.field).append('<div class="frm_error">' + error.message + '</div>');
    });
}

function formPageLeiValidateCurrentStep() {
    var stepNumber = $('.steps-wrapper').attr('data-step');

    var errors = [];
    $('.steps-wrapper .step-' + stepNumber + ' .form-field').each(function () {

        var hasError = false;
        if ($('.frm_required', this).length > 0) {
            if ($.trim($('input,textarea', this).val()) == '') {
                errors.push({
                    field: this,
                    message: 'Ce champ est obligatoire.',
                });
                hasError = true;
            }
        }

        if (!hasError && $('input[name="email"]', this).length > 0) {
            var regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!regexEmail.test(($('input', this).val()).toLowerCase())) {
                errors.push({
                    field: this,
                    message: "L'adresse email est incorrecte.",
                });
            }
        }
    });

    return errors;
}
var geolocOverlay = {

    algoliaIsInit: false,
    markerCenter: null,

    algoliaIndex: null,

    lastSearchtime: null,
    lastCoord: null,

    init: function(){
        $('a[href="#geoloc"]').on('click',function(e){
          e.preventDefault();
          e.stopPropagation();
          geolocOverlay.open();
        });

        if(document.location.hash === '#geoloc'){
          geolocOverlay.open();
        }
    },

    open: function(){


      geoloc.getGeoloc(function(coord){
        setTimeout(function(){
          geolocOverlay.lastCoord = coord;
          geolocOverlay.refreshContent();
        },250);
      });

      mapOverlayManager.open();
      geolocOverlay.initOnOpen();
      geolocOverlay.initMarkerPosition();

    },

    initMarkerPosition: function(){

        mapOverlayManager.showMarkerCenter(true,function(e){
            geolocOverlay.lastCoord = {lat:mapOverlayManager.markerCenter.getLatLng().lat,lng:mapOverlayManager.markerCenter.getLatLng().lng};
            geolocOverlay.refreshContent(true);
        });

    },

    initOnOpen: function(){

        if(geolocOverlay.algoliaIsInit){
            return false;
        }
        geolocOverlay.algoliaIndex = thuriaSearch.client.initIndex('lei_'+$('html').data('lang'));
        geolocOverlay.algoliaIsInit = true;

        return true;
    },

    searchContent: function(timeInit,callback){

        if(!geolocOverlay.algoliaIndex){
            setTimeout(function(){
                geolocOverlay.searchContent();
            },250);
            return false;
        }

        if(timeInit == geolocOverlay.lastSearchtime){

            var dataSearch = {
              aroundRadius: 5000,
              aroundLatLng: geolocOverlay.lastCoord.lat+', '+geolocOverlay.lastCoord.lng,
              hitsPerPage: 100,
            };
            if(geoLocSelectionList && geoLocSelectionList.length > 0){
                dataSearch.filters = 'selections:'+geoLocSelectionList.join(' OR selections:');
            }
            console.log(dataSearch);
            geolocOverlay.algoliaIndex.search(dataSearch,function(err,response){
                if(timeInit == geolocOverlay.lastSearchtime) {
                    callback(response.hits);
                }
            });
        }

    },

    refreshContent: function(isMoveEvent){
        if(!isMoveEvent && mapOverlayManager.markerCenter){
            mapOverlayManager.markerCenter.setLatLng(geolocOverlay.lastCoord);
        }

        geolocOverlay.lastSearchtime = new Date().getTime();
        geolocOverlay.searchContent(geolocOverlay.lastSearchtime,geolocOverlay.displayResults);
    },

    displayResults: function(hits){

        var htmlContent = '';
        $.each(hits,function(k,fiche){
            htmlContent += cardGenerator.generate(fiche);
        });

        mapOverlayManager.updateContentMiniCards(htmlContent);
    },

};

geolocOverlay.init();
var LSenabled = false;

var EdgelinkUser = {

    terms_scores: {},
    datas: {},

    init: function() {
        EdgelinkUser.initializeTerms();
    },

    initializeTerms: function() {
        if(EdgelinkUser.getEdgelinkData()) {
            EdgelinkUser.datas = EdgelinkUser.getData();
            EdgelinkUser.terms_scores = EdgelinkUser.getUserScores();
        }

        viewded_pages = 0;

        if(typeof EdgelinkUser.datas.nb_viewed_pages != 'undefined') {
            viewded_pages = parseInt(EdgelinkUser.datas.nb_viewed_pages) + 1;
        }

        EdgelinkUser.datas.nb_viewed_pages = viewded_pages ;
        EdgelinkUser.datas.currentPostID = currentPostID[0];

        // on parcourt tous les thèmes Edgelink exitants dans la DB
        EdgeTerms.forEach(function(elem){

            if(typeof EdgeContentScore[elem] != 'undefined') {
                // le terme existe sur la page en cours
                if(typeof EdgelinkUser.terms_scores[elem] != 'undefined') {
                    // il existe dans le profil d'utilisateur, on lui ajoute le score
                    EdgelinkUser.terms_scores[elem] = parseInt(EdgelinkUser.terms_scores[elem]) + parseInt(EdgeContentScore[elem]);
                } else {
                    // il n'existe pas encore sur le profil utilisateur, on le créer et en lui ajoutant son score
                    EdgelinkUser.terms_scores[elem] = parseInt(EdgeContentScore[elem]);
                }
            } else {
                if(typeof EdgelinkUser.terms_scores[elem] != 'undefined') {
                    //il n'existe pas sur la page en cours
                    // mais il existe dans le profil utilisateur, on lui applique le coefficient de pondération
                    EdgelinkUser.terms_scores[elem] = parseInt(EdgelinkUser.terms_scores[elem]) / edgelink_coef;
                }
            }
        });

        EdgelinkUser.setEdgelinkData();
        return true;
    },

    getEdgelinkData: function() {
        if(typeof localStorage.Edgelink != 'undefined') {
            parsed = JSON.parse(localStorage.Edgelink);
            if(typeof parsed.datas != 'undefined') {
                return JSON.parse(localStorage.Edgelink);
            }
            return false;
        }
        return false;
    },

    getUserScores: function() {
        if(EdgelinkUser.getEdgelinkData()) {
            user_scores = EdgelinkUser.getEdgelinkData();
            return user_scores.terms_scores;
        }
        return false;
    },

    getData: function() {
        if(EdgelinkUser.getEdgelinkData()) {
            user_scores = EdgelinkUser.getEdgelinkData();
            return user_scores.datas;
        }
        return false;
    },

    setEdgelinkData: function() {
        var global_data = {
            'terms_scores' : EdgelinkUser.terms_scores,
            'datas' : EdgelinkUser.datas
        };

        return localStorage.setItem('Edgelink',JSON.stringify(global_data));
    },

    getLinkedPosts: function() {
        jQuery.ajax({
            type: 'POST',
            url: ajaxurl,
            data: {
                'action': 'getlinkedcontent',
                'param': JSON.stringify(EdgelinkUser.getEdgelinkData()),
            },
            //dataType: 'html',
            // beforeSend: function () {
            //     $('.cs-loader').show();
            // },
            success: function(html) {
                $(''+ edgelink_container_class +'').toggleClass('non-loaded');
                $(''+ edgelink_card_container_class +'').html(html);
                if(typeof edgelinkloadedcallback != 'undefined') {
                    edgelinkloadedcallback();
                }
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                //alert("TECHNICAL ERROR: \n\nDetails:\nError thrown: " + XMLHttpRequest + "\n" + 'Text status: ' + textStatus);
            }
        });
    },

};

var ThLocalStorage = {

    useLocalStorage: function() {
        try {
            var storage = window.localStorage,
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch(e) {
            return e instanceof DOMException && (
                    // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage.length !== 0;
        }
    }
}

jQuery(document).ready()
{
    LSenabled = ThLocalStorage.useLocalStorage();

    if(LSenabled == true) {
        EdgelinkUser.init();
        if($(''+ edgelink_container_class +'').length > 0) {
            EdgelinkUser.getLinkedPosts();
        }
    }
}

